/************************************
	Add preload to DOM
*************************************/


/************************************
	Wait for document loaded
*************************************/
( function ( $ ) {

		// Fixed Navbar
		(function ($) {
			$(document).scroll(function () {
			  var $nav = $(".fixed-top");
			  $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
			});
		  });

	/************************************
		Main js code
	*************************************/
	jQuery(document).ready(function($) {

		"use strict";
		/************************************
	   		Make body always scroll to position 0
		*************************************/
		$(this).scrollTop(0);
		/************************************
	   		Jquery UI
		*************************************/


		
		/************************************
	   		Sliders
		*************************************/
		//Homepage 2 featured cases 
		$('.featured-cases_bottom__style1 .cases-group').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
			adaptiveHeight: true,
			dotsClass: 'slider-dots',
			appendDots: '.latest-blog-slide-control',
		    customPaging : function(slider, i) {
		        return '<div class="dots"></div>';
		    },
		    responsive: [
			    {
			      breakpoint: 992,
			      settings: {
			        slidesToShow: 1,
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 1,
			      }
			    },
			    {
			      breakpoint: 576,
			      settings: {
			        slidesToShow: 1,
			      }
			    },
			    {
			      breakpoint: 0,
			      settings: {
			        slidesToShow: 1,
			      }
			    }
		  	]
		})

	});


	


	/* back-to-top */
$(window).on('scroll', function () {
	var scroll = $(window).scrollTop();
	if (scroll < 400) {
	  $('#back-top').fadeOut(500);
	} else {
	  $('#back-top').fadeIn(700);
	}
  });

} )( jQuery );